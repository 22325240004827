


















































































import HostChatMessageService from '@/services/owner/HostChatMessageService';
import FormatedTimestamp from '@/components/shared/FormatedTimestamp.vue';
import ValidatedTextArea from '@/components/forms/ValidatedTextArea.vue';
import Vue, { VueConstructor } from 'vue';
import { validationMixin } from 'vuelidate';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';
import { maxLength, minLength, required } from 'vuelidate/lib/validators';
import ToastMessage from '@/models/ToastMessage';
import { mapState } from 'vuex';
import OrderStatus from '@/components/orders/OrderStatus.vue';

class ChatMessageDetailDto {
  id!: string;
  createdAt!: string;
  sender!: string;
  messageText = '';
  isRead!: boolean | null;
  orderEventType!: string | null;
  orderId!: string | null;
  shortOrderId!: string | null;
}

const validations = {
  message: {
    messageText: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(5000),
    },
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  components: {
    FormatedTimestamp,
    ValidatedTextArea,
    OrderStatus,
  },
  validations,
  mixins: [FormaterMixin, validationMixin],
  data() {
    return {
      loading: true,
      message: new ChatMessageDetailDto(),
      sending: false,
      ready: false,
      guestDisplayName: '',
      chatMessages: [] as ChatMessageDetailDto[],
    };
  },
  props: {
    guestId: {
      required: true,
      type: String,
    },
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostChatMessageDetail.meta.title').toString(),
    };
  },
  computed: {
    ...mapState('AuthStoreModule', ['user']),
    ...mapState('ChatStoreModule', ['guestIdsWithNewMessages']),

    isSubmitButtonDisabled(): boolean {
      return this.sending || this.message?.messageText?.length == 0;
    },

    shownChatMessages(): number {
      return this.chatMessages?.length;
    },
  },
  watch: {
    shownChatMessages: {
      handler() {
        setTimeout(this.scrollDown, 500);
      },
    },
    guestIdsWithNewMessages: {
      handler() {
        this.loadData();
        this.$store.commit('ChatStoreModule/setGuestIdAsRead', this.guestId);
      },
    },
  },
  methods: {
    parseDate(dateString: string): Date | null {
      return this.parseIsoDateTimeTz(dateString);
    },

    rowClassName(chatMessage: ChatMessageDetailDto): string {
      if (chatMessage.sender == 'Host') {
        return 'justify-content-end';
      } else if (chatMessage.sender == 'System') {
        return 'justify-content-center';
      } else {
        return '';
      }
    },

    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostChatMessageDetail.breadcrumb.last' },
      ]);
    },

    scrollDown() {
      this.$nextTick(() => {
        document.getElementsByClassName('new-message-card')[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
      });
    },

    loadData() {
      HostChatMessageService.getDetail(this.guestId).then((res) => {
        this.chatMessages = res.value.chatMessages.items;
        this.guestDisplayName = res.value.guestName;
        this.ready = true;
        this.loading = false;
        this.scrollDown();
        // Set unread messages to read after 5 seconds
        setTimeout(() => {
          const unreadChatMessages = this.chatMessages.filter((cm) => cm.isRead == false);
          if (unreadChatMessages.length == 0) return;
          const unreadChatMessageIds = unreadChatMessages.map((cm) => cm.id);
          HostChatMessageService.setAsRead(unreadChatMessageIds).then(() => {
            unreadChatMessages.forEach((cm) => (cm.isRead = true));
            // Load new Status
            this.$store.commit('StatusStoreModule/resetUnreadChatMessages', this.guestId);
          });
        }, 5000);
      });
    },

    handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$store.commit(
          'addToastMessage',
          new ToastMessage(this.$t('forms.common.error_message').toString(), 'bg-warning')
        );
      } else {
        this.sending = true;
        HostChatMessageService.sendMessage({ messageText: this.message.messageText, guestId: this.guestId }).then(
          () => {
            this.sending = false;
            this.message = new ChatMessageDetailDto();
            this.loadData();
            this.$v.$reset();
          },
          () => {
            this.sending = false;
          }
        );
      }
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
});
