import ApiService from '@/services/ApiService';

const API_URL = '/api/Owner/ChatMessages/';

class OwnerChatMessageService {
  setAsRead(chatMessageIds: string[]) {
    return ApiService.post(`${API_URL}SetAsRead`, { chatMessageIds });
  }
  sendMessage(message: { guestId: string; messageText: string }) {
    return ApiService.post(`${API_URL}SendMessage`, message);
  }

  getDetail(guestId: string) {
    return ApiService.get(`${API_URL}Detail?guestId=${guestId}&currentPage=1`);
  }

  getIndex(currentPage: number, sortColumn: string, sortAsc: boolean, tableFilter: string) {
    return ApiService.get(
      `${API_URL}Index?currentPage=${currentPage}&sortColumn=${sortColumn}&sortAsc=${sortAsc}&tableFilter=${tableFilter}`
    );
  }
}

export default new OwnerChatMessageService();
